import { Container, Row, Col, Image } from "react-bootstrap";

// card design
// const StaffMember = ({ name, title, imageSrc, altText, description }) => {
//   return (
//     <Container className="border border-dark rounded my-4">
//       <Row>
//         <Col lg={4} md={12} className="p-0">
//           <Image src={imageSrc} alt={altText} className="img-fluid w-100 h-auto" />
//         </Col>
//         <Col className="p-4 d-flex flex-column justify-content-center">
//           <h2>{name}</h2>
//           <h4 className="text-muted">{title}</h4>
//           <div dangerouslySetInnerHTML={{ __html: description }} />

//           {/* {description.map((paragraph, index) => (
//             <p key={index}>{paragraph}</p>
//           ))} */}
//         </Col>
//       </Row>
//     </Container>
//   );
// };


const LeadershipPage = () => {
  document.title = "COMPACT Leadership";

// staff data
// const staffMembers = [
//   {
//     name: "Dr. Alan Bixler",
//     title: "EXECUTIVE DIRECTOR",
//     imageSrc: require("../img/bixler.jpg"),
//     altText: "Dr. Alan Bixler",
//     description: [
//       "",
      // Add more paragraphs as needed
//     ],
//   }
// ]


  return (
    <>

{/* map members  */}
      {/* {staffMembers.map((member, index) => (
        <StaffMember key={index} {...member} />
      ))} */}

      <Container className="my-4 blue">
        <h3>Leadership</h3>
      </Container>

      <Container className="my-4">
  <Row className="border border-dark rounded overflow-hidden">
    <Col lg={4} md={12} className="p-0">
            <Image fluid src={require("../img/bixler.jpg")} />
          </Col>
          <Col className="p-4 d-flex flex-column justify-content-center">
            <div className="ps-3">
              {/* {leaders && <div>
                  {leaders.posts.edges.mooney}
                      </div>} */}

              <h2>Dr. Alan Bixler</h2>
              <h4>EXECUTIVE DIRECTOR</h4>
              <p>
                Assemblies of God COMP
                <span className="act">ACT</span>®  Family Services is led by Dr. Alan Bixler, DSL, MOL.
              </p>
              <p>
              Alan joined  COMP
                <span className="act">ACT</span> in April of 2022. When it comes to leadership and strategic community engagement, Alan has a wealth of education, knowledge, and experience. Alan and his wife Heather served as Lead Pastors at Crosswalk Community Church, in Sioux Falls, SD for eight years prior to joining  COMP
                <span className="act">ACT</span>.
              </p>
              <p>
              Alan has over three decades of leadership, ministry, and outreach influence resulting in various leadership specialties and targeted demographical experiences along a broad scope of industries including church and corporate opportunities.

Alan is dedicated to the mission of  COMP
                <span className="act">ACT</span> in ministering to vulnerable children and families through COMPassionate ACTion. His primary goals include raising leaders that resource the Church to redeem lives through Jesus Christ.
              </p>
            </div>
          </Col>
        </Row>
      </Container>

      {/* <Container className="my-4">
  <Row className="border border-dark rounded overflow-hidden">
    <Col lg={4} md={12} className="p-0">
            <Image
              fluid
              src="https://compact.family/images-staff/BrianPage_t.jpg"
              layout="intrinsic"
              width="400"
              height="487"
              // objectFit="cover"
              alt="Brian Page"
            />
          </Col>
          <Col className="p-4 d-flex flex-column justify-content-center">
            <div className="ps-3">
              <h2>Brian Page, MSW, LCSW</h2>
              <h4 className="text-uppercase">Prevention Services Director</h4>
              <p>
                Brian Page joined COMP
                <span className="act">ACT</span>
                 in 2017. He has a rich child welfare and leadership background
                that will serve this growing agency. The role of Administrator
                serves directly under the leadership of the agency’s Executive
                Director. Brian also oversees COMP
                <span className="act">ACT</span>’s preventative program,
                SafeCare, designed to help at-risk families keep their children
                out of foster care.
              </p>
              <p>
                Regarding his new assignment as Administrator with Assemblies of
                God COMP
                <span className="act">ACT</span>
                 Family Services, Brian says, “In 1993, God gave me a vision of
                working with vulnerable children and youth. In my social work
                career, I have had the opportunity to serve as a relief
                houseparent at Hillcrest Children’s Home, social services
                director with Pleasant Hills Children’s Home, and professional
                counselor in education and military contexts. Yet this new
                opportunity fills a vacant part of that life-shaping dream from
                almost 25 years ago. I am excited to work with such great people
                in a professional work with a Christ-centered culture to help
                redeem the fatherless and family need in this world. I am
                humbled to join COMP
                <span className="act">ACT</span> in this vision and mission.”
              </p>
            </div>
          </Col>
        </Row>
      </Container> */}

      <Container className="my-4">
  <Row className="border border-dark rounded overflow-hidden">
    <Col lg={4} md={12} className="p-0">
      <Image
        src="https://compact.family/images-staff/johan2022.jpg"
        alt="Johan Mostert"
        className="img-fluid w-100 h-auto"
      />
    </Col>
    <Col className="p-4 d-flex flex-column justify-content-center">
      <h2>Johan Mostert, PhD</h2>
      <h4 className="text-muted">National CompaCare Director</h4>
      <p>
        Dr. Mostert, before joining the COMP<span className="act">ACT</span> staff, 
        served in South Africa managing welfare services. In America since 2004, 
        he's been part of AGTS as a Professor of Community Psychology.
      </p>
      <p>
        He joined COMP<span className="act">ACT</span> in 2016, leading the Family 
        and Community Resources division and contributing to professional program 
        enhancements and research.
      </p>
      <p>
        As Professor Emeritus at AGTS, he coordinates the PhD Intercultural 
        Studies program and teaches in the DMin and MDiv programs.
      </p>
    </Col>
  </Row>
</Container>




<Container className="my-4">
  <Row className="border border-dark rounded overflow-hidden">
    <Col lg={4} md={12} className="p-0">
            <Image
              fluid
              src="https://compact.family/images-staff/LanceNelson_t.jpg"
              layout="intrinsic"
              width="400"
              height="477"
              // objectFit="cover"
              alt="Lance Nelson"
            />
          </Col>
          <Col className="p-4 d-flex flex-column justify-content-center">
            <div className="ps-3">
              <h2>Lance Nelson</h2>
              <h4 className="text-uppercase">National Foster Care Director</h4>
              <p>
                Lance Nelson joined the COMP
                <span className="act">ACT</span> staff in 2015. He has served in
                local church ministry since 2000, pastoring three churches in
                Oklahoma. Lance is a graduate of Southwestern Assemblies of God
                University in Waxahachie, Texas where he earned a bachelor of
                science in church ministries, and studied at Assemblies of God
                Theological Seminary earning a Master in Pastoral Leadership. He
                is also an ordained minister through Arkansas District Council
                of the Assemblies of God. Additionally he is a Practitioner in
                the Trust-Based Relational Intervention (TBRI) model.{" "}
              </p>
              <p>
                Lance’s passion is to see the church embrace the mission of
                redeeming vulnerable children and push back against the
                destructive trend of families disintegrating. As the agency’s
                foster care director, Lance is responsible for empowering
                churches to deploy the CompaCare model of support for foster
                families, as well as recruiting, training, and supporting foster
                families in Arkansas. He also oversees the placement of children
                in COMP
                <span className="act">ACT</span> Foster Homes.
              </p>

              <p>
                Lance’s wife Lindsey also serves at COMP
                <span className="act">ACT</span> Family Services. Together the
                couple has 5 children.
              </p>
            </div>
          </Col>
        </Row>
      </Container>
      <Container className="my-4">
  <Row className="border border-dark rounded overflow-hidden">
    <Col lg={4} md={12} className="p-0">
            <Image
              fluid
              src="https://compact.family/images-staff/AshleyGrant_t.jpg"
              layout="intrinsic"
              width="400"
              height="499"
              // objectFit="cover"
              alt="Ashley Grant"
            />
          </Col>
          <Col className="p-4 d-flex flex-column justify-content-center">
            <div className="ps-3">
              <h3>Ashley Grant</h3>

              <h4>MISSOURI PROGRAMS DIRECTOR</h4>
              <p>
                Ashley is COMP
                <span className="act">ACT</span>'s Missouri Programs Director
                and Highlands Adoptions Services Director. In her roles, Ashley
                supervises the FIND, a kinship navigation and family finding
                service for children in care and oversees the home study program
                in Missouri for foster families who are getting licensed.
                Additionally, she is the author/creator of Highlands Connect, an
                online parenting course for pregnant women and single moms.
                Ashley holds her M.A in Human Services Marriage and Family
                Counseling from Liberty University, has published her first
                book, and has had the honor of speaking on a national platform
                on the issue of human trafficking. Ashley has been blessed to
                foster 9 children in the past 6 years and is currently raising 4
                incredible kids as a single mom. Her passion for healthy
                children and families is contagious and her God-given drive to
                restore the family is what keeps her going. Ashley hopes that
                her testimony will encourage many of you to continue to fight
                for your children and grab hold of the identity the Lord has
                given you.
              </p>
            </div>
          </Col>
        </Row>
      </Container>

      <Container className="my-4">
  <Row className="border border-dark rounded overflow-hidden">
    <Col lg={4} md={12} className="p-0">
            <Image
              fluid
              src="https://compact.family/images-staff/ladana.jpg"
              layout="intrinsic"
              width="400"
              height="300"
              // objectFit="cover"
              alt="LaDana Pate"
            />
          </Col>
          <Col className="p-4 d-flex flex-column justify-content-center">
            <div className="ps-3">
              <h2>LaDana Pate</h2>
              <h4>ARKANSAS RESIDENTIAL DIRECTOR</h4>
              <p>
                LaDana Pate has been with COMP
                <span className="act">ACT</span> since May 2015. She started out
                as Program Manager over Contracts and is now our Residential
                Director. In her position, LaDana oversees our case managers,
                direct care staff, and residents to provide care for foster
                children, pregnant teens, teen mothers, and large sibling groups
                in our care. She trains direct care staff and case managers,
                supervises child placements, working directly with the state in
                placements, and collaborates with the team on creating programs
                and manuals.{" "}
              </p>
              <p>
                LaDana has a 6-year tenure as a preschool teacher, worked 4
                years as a trainer for University of Arkansas and has 12 years’
                experience working with government and state regulations,
                including Head Start director. Her passion is for advocating for
                abused or neglected children and researching ways to work with
                various special needs of children.
              </p>

              <p>
                Her degrees include: Bachelor’s in Education, Master’s in
                Special Education, Master’s in Psychology and she is currently
                working on her MDiv specialized in counseling.
              </p>
            </div>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default LeadershipPage;
